#round {
    border-radius: 10px;
    border-color: gray;
    height: 10px;
  }
  #round1 {
    border-radius: 10px;
    border-color: gray;
    height: 42px;
    width: 430px;
  }
.get_direction_btn_for_track {
  font-family: Open Sans !important;
  font-style: normal;
  font-weight: 700;
  /* font-size: 14px; */
  background-color: #8a2432 !important;
  border-radius: 13px !important;
  color: #FFF !important;
  border-color: #FFF !important;
}
.close-button {
  bottom: 3%;
  left: 30%;
  width: 220px;
  height: 50px;
  padding: unset !important;
  background: #8A2432;
  border-radius: 8px;
}
  @media (max-width:559px) {
    .mobile-view-page{
      /* margin-top:70px */
      position:relative;
      top:60px;
  }

  .org-header__btn--circle{
      width:40px;
      height:40px;
  }

  .track-text-mobile {
    margin-top:10px;
  }

  body {
    font-size: 16px;
  }
  input[type="text"] {
    font-size: inherit;
  }
  }
  #round2 {
    border-radius: 10px;
    border-color: gray;
    height: 150px;
    width: 100%;
    resize: none;
  }
  
  textarea {
    padding: 6px 8px;
    line-height: 1.5;
    resize: none;
  }
  #txt {
    color: rgb(100, 99, 99);
  }
  #content{
    text-align: center;
    vertical-align: middle;
  }
  #h{
    border-radius: 10px;
    background-color:#8a2432;
  }

  @media (max-width:400px) {
    .get_direction_btn_for_track {
      width: 100%;
    }
    .track-order {
      width: 100%;
    }
  }