footer .widget-title {
	font-family: Open Sans;

	font-weight: 800;
	font-size: 16px;

	color: #222720;
}

.footer .widget-newsletter .btn {
	padding: 8px 0.475rem;
	background-color: #222720;
	font-family: Open Sans;
	color: white;
	width: 44%;
	border-radius: unset;
	/* font-size: inherit; */
	/* font-family: "Open Sans",sans-serif; */
	border-radius: 7px;
	padding-block: 10px;
}

.links {
	font-family: Open Sans;
	font-weight: 400;
	font-size: 16px;
	color: #FFFFFF;
	margin-right: 38px;
}

.text-h-footer {
	color: #4b4c4d !important;
}

.text-sh {
	color: #4b4c4d !important;
}

.bg-btn-nl {
	background-color: #ffd400 !important;
}


.bg-footer {
	background-color: #8a2432;
}

.footer-middle .social>a {
	font-size: 24px;
	width: 40px;
	height: 40px;
	line-height: 35px;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	/* fill: white; */
	/* background-color: #FFFFFF; */
	/* border: 1px solid #ccc; */
	margin: 0 21px;

}


.footer-middle .copyright {
	margin-top: 15px;
	text-align: left;
	font-size: 13px;
	color: #aaa;
	margin-bottom: 0;
}

.footer-middle .powered {
	margin-top: 15px;
	text-align: left;
	font-size: 13px;
	color: #aaa;
	margin-bottom: 0;
}

.subscribe-btn:hover {
	background-color: #8a2432;
	color: #FFF !important;
}

.twitter-btn:hover {
	background-color: #00acee;
	color: #FFF !important;
}

.phone-btn:hover {
	background-color: #8a2432;
	color: #FFF !important;
}

.facebook-btn:hover {
	background-color: #3b5998;
	color: #FFF !important;
}

.instagram-btn:hover {
	background: linear-gradient(45deg,
			#f09433 0%,
			#e6683c 25%,
			#dc2743 50%,
			#cc2366 75%,
			#bc1888 100%);
}

.customer-service-mob {
	margin-left: 37%;
}

.icons-outer {
	font-size: 40px;
}

.join_our_news {
	border: none;
	font-Size: 15px;
	width: 72%;
	padding: 6px 10px;
	border-radius: 7px;
}

.email-add {
	display: flex;
	align-items: center;
	gap: 5px;

}
.email-add svg{
	font-size: 20px;
}
.email-add a {
	color: #fff;
	font-size: 16px;
}

@media screen and (max-width:559px) {
	.footer-text-mobile {
		margin-right: 25px !important
	}

	.footer {
		margin-top: 45px;
	}
}

@media (max-width: 767px) {
	.email-add{
		justify-content: center;
	}
	.footer-mobile {
		justify-content: center !important;
	}
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
	.footer-logo-styling-mobile-view {
		width: 250px;
		/* margin: 0 0; */

	}

	.text-left {
		text-align: center !important;
	}

	.social {
		text-align: center !important;
	}

	.join_our_news-mob {
		text-align: center !important;
		margin: 0 10px;
	}

	.join_our_news {
		text-align: center !important;
		margin-left: unset;

	}

	.customer-service-mob {
		margin-left: unset;
		margin: 0 10px;
	}

	.icons-outer {
		font-size: 40px;
		margin-top: 20px;
		margin: 20px 0;
	}



}

@media (min-width: 1222px) and (max-width: 2448px) {
	.footer-responsive {
		max-width: auto !important;
		/* margin-top: unset; */
		background-color: rgb(138, 36, 50);
		margin: auto !important;
	}
}

@media (max-width:559px) {
	body {
		font-size: 16px;
	}

	input[type="text"] {
		font-size: inherit;
	}
}