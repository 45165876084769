

.vat-tax{
  color:#FF8300 !important;

}
.disable {
  pointer-events:none; 
  opacity:0.6;         
}
.card-modal{
  width:36% !important
}
.checkout-progress-bar li.active>span {
  color: #000;
  font-family: "Open Sans";
  font-weight: 500;
}
label {
  margin: 0px 10px 0.6rem ;
  color: #000;
  font-family: "Open Sans" !important;
  font-size: 16px;
  font-weight: 700;
}
.label-arabic {
  margin: 0px 39px 0.6rem !important;
  color: #000;
  font-family: "Open Sans" !important;
  font-size: 16px;
  font-weight: 700;
}
.checkout-progress-bar li>span:after {
  top: 0.6rem;
  width: 2.6rem;
  height: 2.6rem;
  margin-left: -1.3rem;
  background: #fff;
  color: black;
  font-weight: 600;
  content: counter(i);
  counter-increment: i;
}
.locationsec {
  padding: 25px 30px 30px;
  border: 1px #e5e5e5 solid;
}
.locationTitle {
  font-size: 24px;
  text-transform: uppercase;
  color: #000;
  margin: 0 0 15px;
  font-family: "Open Sans" !important;
  letter-spacing: normal;
}
.loginCart {
  width: 100%;
  background-color: #f6f6f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px 12px 35px;
}
.loginCart p {
  color: #a6a7a8;
  margin: 0;
  font-family: "Open Sans";
  font-size: 1.3rem;
  line-height: 1.9rem;
}
.orContinue {
  margin: 20px 0;
}
.loginCartButton {
  background-color: #8a2432;
  border: 0;
  color: #fff;
  font-size: 12px;
  padding: 6px 16px;
  box-shadow: none;
  text-transform: uppercase;
}

.deliverycart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px 12px 35px;
}
.pickupcart {
  color: #fff;
  font-size: 12px;
  /* padding:6px 16px; */
  box-shadow: none;
  text-transform: uppercase;
}
.iwant-sec {
  float: left;
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  padding-bottom: 15px;
  border-bottom: 1px dotted #ddd;
}
.iwnt-toggleButton {
  margin: 0;
  display: flex;
  list-style: none;
  font-family: "Open Sans" !important;
  font-size: 16px;
  text-transform: uppercase;
}
.iwnt-toggleButton li {
  margin: 0 30px 0 0;
  padding: 5px 0 5px 0;
  display: flex;
  line-height: 1;
  align-items: center;
  cursor: pointer;
}

.order_method_radio {
  margin-right: 15px;
}

.cart-nowLater {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  margin-left: -10%;
}

.later-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-addressCart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
}

.name-deliveryCart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  align-content: center;
  padding: 20px;
  padding-top: 4%;
}

.storeName-cartD {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  background-color: #fff;
  align-content: center;
  padding-top: 4%;
  margin-top: -25px;
}
/* Dropdown Button */
.dropbtn {
  background-color: #8a2432;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
}

.storeShow-cart {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  color: white;
  background-color: white !important;
  padding: 8px;
  border: 0.5px solid #8a2432;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  /* width: 60%; */
}
.hideInputK {
  margin-top: -3%;
  display: flex;
  justify-content: space-around;
}
.notek {
  color: black;
  padding: 10px;
  width: 40%;
  padding-bottom: 50px;
}
.hideInputFieldK {
  width: 90%;
  height: 30%;
}
.hideInputFieldPickup {
  width: 40%;
  height: 40%;
  padding: 12px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.sl-hds {
  font-size: 24px;
  text-transform: uppercase;
  color: #000;
  margin: 0 0 15px;
  font-family: "Trade Gothic LT Std Bold" !important;
  letter-spacing: normal;
}

.clearfixButton {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 768px) and (min-width: 300px) {
  h2 {
    font-size: 24px;
    letter-spacing: -1px;
  }
  #responsiveTabs{
    width:33% !important
  }
}
 
.myButton {
  width: 100%;
}
.myButton:hover {
  background: linear-gradient(to bottom, #e4685d 5%, #fc8d83 100%);
  background-color: #e4685d;
}
.myButton:active {
  position: relative;
  top: 1px;
}
 
.lgor {
  font-family: "open_sansregular", Arial, Helvetica, sans-serif !important;
  text-align: center;
  font-size: 15px;
  margin: 0 0 20px;
  border-bottom: 1px dotted #c7c7c7;
  width: 80%;
  display: table;
  font-style: normal;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.colorLink{
  color: #8a2432 !important;
}
.colorLink:hover {
  text-decoration: underline !important;
}

.dropdown-content a {
  color: #8a2432;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #8a2432;
}
#round {
  border-radius: 10px;
  border-color: gray;
  height: 10px;
}
#roundPick {
  border-radius: 10px;
  border-color: gray;
  height: 50px;
  width: 100%;

}

#roundSelect {
  border-radius: 10px;
  border-color: gray;
  height: 42px;
}


@media (max-width: 800px) {
  .noteK {
    width: 70%;
  }
  .hideInputFieldK {
    width: 70%;
    height: 15%;
  }
  #nowMobile{
    margin: 0px !important;
  }
}

.cart-summary h4 a:after {
  display: none !important;
 
}
.search-wrapper{
border: none;
display: flex;
align-items: center;
padding-right: 10px;
border: 1px solid #eaeaea;
z-index: 9;
background: #fff;
width: 80%;
margin-top: 5px;
left: 10%;
position: absolute;
}
.autoComplete{
  outline: none;
  border-radius: unset;
  border: 2px solid ;
}
.discountStyle{
  font-weight: bold;
  color: green;
}


.address-suggestion {
  position: absolute;
  background-color: #fff;
  /* position: absolute; */
  z-index: 100;
  width: 93%;
  top: 50px;
  width: 93%;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
}

.radiobtnfix {
    position: absolute;
    margin-top: 0.3rem;
}


.suggestion-item{
  padding: 12px;
  display: grid;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
}
.suggestion-item:hover{
  background-color: #eaeaea;
}
.laterDateInput {
  padding: 5px;
  height: 4rem;
  border-color: gray;
  border-radius: 10px;
}
.font-color{
  color:#8a2432 !important;
}
.btn-bg-custom{
  background-color: #8a2432;
}
.cart-button{
  background-color: #8a2432 !important;
  color: #FFF !important;
  border-color: #FFF !important;
}
.cart-button:hover{
  background-color: #8a2432 !important;
}
.modal-custom-style{
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 100vh;
}
.pinIcon{ 
  position: absolute; 
  margin-left: -23px;
  margin-top: 90px; 
  width: 45px;
  height: 45px;
  left: 50%;
   top: 15px;
  z-index: 1 
  }
.currentLocation{
  position: absolute; 
  margin-left: 150px;
  margin-top: -4px; 
  cursor: pointer;
  width: 25px;
  height: 25px;
  left: 70%;
  top: 25px;
  z-index: 1 
}
  .autoComplete{
  width: 94%;
  height: 45px;
  padding:20px;
  border: none;
  /* border-radius: 5px; */
  /* position: absolute; */
   top: 110px;
  z-index: 1
  }

  .custom-focus-control:focus{
    /* border-color: #28a745; */
    box-shadow: 0 0 0 0.2rem rgb(149, 10, 28,0.75);
  }
  .h-4rem{ 
    height: 4rem !important;
  }
  .mtop{
    margin-top: 36px !important;
  }
  /* //To make map resposive */
  @media only screen and (min-width: 1201px) {
    [dir="rtl"] .currentLocation{
      margin-right: 150px;
      right: 70%
    }
    }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {

    .currentLocation{
      margin-left: 110px;
    }
    [dir="rtl"] .currentLocation{
      margin-right: 150px;
      right: 70%
    }
    }

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .pinIcon{
    margin-left: -23px !important;
     margin-top: 90px !important;
       left: 50% !important;
        top: 20px !important;
 }
.autoComplete{
  /* margin-left: 10px !important; */
  top: 110px !important;
}
.currentLocation{
  margin-left: 80px;
  margin-top: -5px; 
  left: 80%;
}
[dir="rtl"] .currentLocation{
  margin-right: 80px;
  right: 75%
}
[dir="rtl"]  .autoComplete{
  margin-right: 20px !important;
}
}
@media only screen and (min-width: 560px) and (max-width: 768px) {
  .pinIcon{
    margin-left: -23px !important;
     margin-top: 75px !important;
       left: 50% !important;
        top: 30px !important;
 }
 .autoComplete{
  /* margin-left: 10px !important; */
  top: 92px !important;
}
.currentLocation{
  margin-left: 50px;
  margin-top: -5px; 
  left: 80%;
}
[dir="rtl"] .currentLocation{
  margin-right: 80px;
  right: 75%
}
}
@media (max-width:559px){
  body {
    font-size: 16px;
  }
  input[type="text"] {
    font-size: inherit;
  }
}
@media only screen and (min-width: 320px) and (max-width: 559px) {
  .pinIcon{
    margin-left: -23px !important;
     margin-top: 70px !important;
       left: 50% !important;
        top: 35px !important;
 }
 .autoComplete{
   width: 94%;
  margin-right: 10px !important;
  top: 87px !important;
}
.currentLocation{
  margin-left: 50px;
  margin-top: -5px;
}
[dir="rtl"] .currentLocation{
  margin-right: 40px;
  right: 70%
}
[dir="rtl"]  .autoComplete{
  margin-right: 20px !important;
}
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
.currentLocation{
  margin-left: 45px;
  margin-top: -5px;
}
}

.StripeElement {
  background-color: #fff;
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  display: block;

margin: 10px 0 20px 0;

width: 100%;

padding: 10px 14px;

font-size: 1em;

font-family: "Source Code Pro", monospace;
outline: 0;

border-radius: 4px;

background: white;


height: 44px;
  }

  .StripeElement--focus {
    outline: 0;
    border: 3px solid;
    border-color: #0573e180;
    /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px #0573e180, 0 1px 1px 0 #0573e180; */
    --focusOutline: 0;
  }

  .card-section{
    height: 150px;
    padding: 20px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
    flex-direction: column;
    box-shadow: 11px 9px 20px 1px rgba(237,237,237,0.75);
    -webkit-box-shadow: 11px 9px 20px 1px rgba(237,237,237,0.75);
    -moz-box-shadow: 11px 9px 20px 1px rgba(237,237,237,0.75);
    background: linear-gradient(45deg, black, transparent);
  }

  .banner {
    height: 30%;
    background-color: black;
  }