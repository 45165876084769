body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
[dir='rtl'] .form-footer .btn {
  margin-left: 3rem !important;
  margin-right: 0rem ;
}
[dir='rtl'] .footer-middle {
  text-align: right;
}
[dir='rtl'] .breadcrumb-item+.breadcrumb-item:before {
  color: inherit;
  padding-left: 11px;
  content: "";
  font-size: 12px;
  font-family: 'porto';
  vertical-align: middle;
  margin-top: -.5rem;
  transform: rotate(180deg);
}
[dir='rtl'] .social-icons .header-icon,.cart-dropdown {
  margin-left: 1.2rem;
}

[dir='rtl'] .sidebar .list li a:before {
  margin: 0.6rem -8px 0px -1rem
}
[dir="rtl"]  .container {
  text-align: right !important;
}
[dir="rtl"]  .react-tel-input .form-control {
  padding-right: 50px !important;
}
[dir="rtl"]  .react-tel-input .flag  {
  margin-right: 10px !important;
}
[dir="rtl"] .cart-dropdown .dropdown-menu{
  left:0;
  right:auto
}
[dir="rtl"] .cart-dropdown .dropdown-menu:before{
  left:28px;
  right:auto
}
[dir="rtl"] .now,.later{
  margin-right:10px
}
[dir="rtl"] .cash{
  margin-left: 15px;
}

[dir="rtl"] .address-suggestion{
  margin-right: 10px;
}


[dir="rtl"] .autoComplete{
  margin-right: unset;
}
[dir="rtl"] .search-wrapper{
  padding-left: 10px;
}
[dir="rtl"] input[type='radio']:after{
  left: 1px;
}
[dir="rtl"] input[type = radio]:checked::after{
  left: 1px;
}
[dir="rtl"] .mod-span{
  margin-right: 20px;
}
[dir="rtl"] input[type = checkbox]:checked::after {
  margin-right: 3px;
}

[dir="rtl"] .order_method_radio {
  margin-left: 15px;
  margin-right: unset;
}