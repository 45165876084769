
.search-del-bar {
    display: flex;
    align-items: center;
    padding-right: 10px;
    border: 2px solid #4C4948;
    z-index: 9;
    background: #fff;
    width: 80%;
    margin-top: 5px;
    left: 9%;
    top: unset;
    position: absolute;
  }


  .btn-outer-center {
    background: #FF8300 !important;
    border-radius: 12px;
    /* width: 100%; */
    font-family: Open Sans;
    /* height: 52px; */
    border: none;
    color: #FFF !important;
    cursor: pointer;

}

  @media (max-width:915px) {
    .mobile-view-page{
        /* margin-top:70px */
        position:relative;
        top:65px;
    }

    .myprofile-mobile{
        text-align: center;
    }
    body {
        font-size: 16px;
      }
      input[type="text"] {
        font-size: inherit;
      }
    .org-header__btn--circle{
        width:30px;
        height:30px;
    }
  }
.search-bar {
    display: flex;
    align-items: center;
    padding-right: 10px;
    border: 2px solid #4C4948;
    z-index: 9;
    background: #fff;
    width: 80%;
    margin-top: 5px;
    left: 5%;
    top: 0rem;
    position: absolute;
}
.myprofile{
    font-family: Open Sans;
    color: #000000;
}
.card {
    padding-top: 20px;
    margin: 10px 0 20px 0;
    background-color: rgba(214, 224, 226, 0.2);
    border-top-width: 0;
    border-bottom-width: 2px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.card .card-heading {
    padding: 0 20px;
    margin: 0;
}

.card .card-heading.simple {
    font-size: 20px;
    font-weight: 300;
    color: #777;
    border-bottom: 1px solid #e5e5e5;
}

.card .card-heading.image img {
    display: inline-block;
    width: 46px;
    height: 46px;
    margin-right: 15px;
    vertical-align: top;
    border: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.card .card-heading.image .card-heading-header {
    display: inline-block;
    vertical-align: top;
}

.card .card-heading.image .card-heading-header h3 {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    color: #262626;
}

.card .card-heading.image .card-heading-header span {
    font-size: 12px;
    color: #999999;
}

.card .card-body {
    padding: 0 20px;
    margin-top: 20px;
    padding-bottom: 10px;
}

.card .card-media {
    padding: 0 20px;
    margin: 0 -14px;
}

.card .card-media img {
    max-width: 100%;
    max-height: 100vh;
}

.card .card-actions {
    min-height: 30px;
    padding: 0 20px 20px 20px;
    margin: 20px 0 0 0;
}

.card .card-comments {
    padding: 20px;
    margin: 0;
    background-color: #f8f8f8;
}

.card .card-comments .comments-collapse-toggle {
    padding: 0;
    margin: 0 20px 12px 20px;
}

.card .card-comments .comments-collapse-toggle a,
.card .card-comments .comments-collapse-toggle span {
    padding-right: 5px;
    overflow: hidden;
    font-size: 12px;
    color: #999;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-comments .media-heading {
    font-size: 13px;
    font-weight: bold;
}

.card.people {
    position: relative;
    display: inline-block;
    width: 170px;
    height: 300px;
    padding-top: 0;
    margin-left: 20px;
    overflow: hidden;
    vertical-align: top;
}

.card.people:first-child {
    margin-left: 0;
}

.card.people .card-top {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 170px;
    height: 150px;
    background-color: #ffffff;
}

.card.people .card-top.green {
    background-color: #53a93f;
}

.card.people .card-top.blue {
    background-color: #427fed;
}

.card.people .card-info {
    position: absolute;
    top: 150px;
    display: inline-block;
    width: 100%;
    height: 101px;
    overflow: hidden;
    background: #ffffff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.card.people .card-info .title {
    display: block;
    margin: 8px 14px 0 14px;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #404040;
}

.card.people .card-info .desc {
    display: block;
    margin: 8px 14px 0 14px;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    color: #737373;
    text-overflow: ellipsis;
}

.card.people .card-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    line-height: 29px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.card.hovercard {
    position: relative;
    padding-top: 0;
    overflow: hidden;
    text-align: center;
    background-color: rgba(214, 224, 226, 0.2);
}

.card.hovercard .cardheader {
    background-color: #8a2432;
    /* background: url("http://lorempixel.com/850/280/nature/4/"); */
    background-size: cover;
    height: 135px;
}

.card.hovercard .avatar {
    position: relative;
    top: -50px;
    margin-bottom: -50px;
}

.card.hovercard .avatar img {
    width: 120px;
    height: 120px;
    max-width: 120px;
    max-height: 120px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 5px solid rgba(255,255,255,0.5);
}

.card.hovercard .info {
    padding: 4px 8px 10px;
}

.card.hovercard .info .title {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 1;
    color: #262626;
    vertical-align: middle;
}

.card.hovercard .info .desc {
    font-family: 'Bespoke Serif' !important;
    overflow: hidden;
    font-size: 12px;
    line-height: 20px;
    color: black;
    text-overflow: ellipsis;
}

.card.hovercard .bottom {
    padding: 0 20px;
    margin-bottom: 17px;
}
#txt{
    font-size: 20px;
    color: unset;
}

#txt2{
    font-size: 14px;
    color: unset;
}
.fa, .fas {
    font-weight: 900;
    /* color: #222529; */
}
.add-address-style{
    cursor: pointer;
    float: right;
    font-size: 1.3em;
}

table{
    margin: auto;
}

.cart-button{
    background-color: #8a2432 !important;
    color: #FFF !important;
    border-color: #FFF !important;
  }
  .cart-button:hover{
    background-color: #c00a27 !important;
  }

  .order-history-headers{
    font-family: 'Open Sans';
    font-size: 15px;
    color: #000000;
    font-weight: bold;
  }

  .laterDateInput{
    padding: 5px;
    height: 4rem;
    border-color: gray !important;
    border-radius: 10px;
  }
  .fa-cart-plus{
color:white;
margin-top: 5px;
  }
  .reponsive-title{
    font-family: Open Sans !important;
    font-size: 15px;
    padding-top: 7px !important;
}

  @media only screen and (max-width:991px) and (min-width:320px){
    .reponsive-title{
        font-family: Open Sans !important;
        font-size: 13px !important;
        padding-top: unset !important;
    }
    .responsive-order-detail{
        font-size: 12px !important;
    }
  }