/* ABID CODE START */
.add-another-address-button{
    margin-left: 32%;
}
.landing-banner-image{
        width:70%
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: auto !important;
    pointer-events: none;
}
.direction-btn{
    background-color: #8a2432 !important;
    color: white;
    width: 133px;
    padding: 10px;
    border-radius: 8px;
    margin-right: 5px;
}
.close {
    text-shadow: unset !important;
    opacity: 1 !important;
}
.get_direction{
    background-color:white !important;
    padding:10px
}
.get_direction_text{
    color:#8a2432 !important;
}
.pickup-text{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
}
fieldset {
    border: 1px solid #000;
}

.modal-header {
    padding-right: 3rem;
    padding-left: 3rem;
    height: 12rem;
    border-bottom: unset !important;
}

.modal-pickup-header .close {
    padding: 1rem;
    margin: -10rem -1rem 0rem auto !important;
}

.modal-pickup-header {
    padding-right: 3rem;
    padding-left: 3rem;
    height: 5rem;
    border-bottom: unset !important;
}
.modal-dialog{
    max-width:  650px;
}

.modal-footer2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    border-top: unset !important;
}
.modal-footer1 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    /* height: auto; */
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    border-top: unset !important;
}

.css-tlfecz-indicatorContainer {
    color: #000000;

    padding: 8px;
    transition: color 150ms;
    box-sizing: border-box;
}

.css-1gtu0rj-indicatorContainer {
    color: #000000 !important;

    padding: 8px;
    transition: color 150ms;
    box-sizing: border-box;
}

.css-1pahdxg-control {
    border: 2px solid #4C4948 !important;
    /* -webkit-box-align: center; */
    -ms-flex-align: center;
    /* align-items: center; */
    /* background-color: hsl(0, 0%, 100%); */
    /* border-color: hsl(0, 0%, 80%); */
    /* border-radius: 4px; */
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    /* -webkit-flex-wrap: wrap; */
    /* flex-wrap: wrap; */
    /* -webkit-box-pack: justify; */
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    /* outline: 0!important; */
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
}

.css-1s2u09g-control {
    border: 2px solid #4C4948 !important;
    /* -webkit-box-align: center; */
    -ms-flex-align: center;
    /* align-items: center; */
    /* background-color: hsl(0, 0%, 100%); */
    /* border-color: hsl(0, 0%, 80%); */
    /* border-radius: 4px; */
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    /* -webkit-flex-wrap: wrap; */

    /* flex-wrap: wrap; */
    /* -webkit-box-pack: justify; */
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    /* outline: 0!important; */
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
}

.delievery-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    position: absolute;
    color: #000000;
    top: 1rem;
}

.delievery-subtitle {
    padding-right: 15px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 31px;
    color: #000000;

    z-index: 100;
    position: absolute;
    top: 4rem;
    background: white;
    left: 49px;
    /* padding: 10px 15px; */
    padding-left: 15px;
}

.css-14el2xx-placeholder {
    font-family: Open Sans;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 18px;
    text-transform: capitalize;
    padding-left: 12px;
    color: #000000;
}

.delievery-pickup-subtitle {
    padding-right: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 31px;
    color: #000000;
    z-index: 100;
    position: absolute;
    top: 12.4%;
    background: white;
    left: 18px;
    padding-bottom: -17px;
    /* padding: 10px 15px; */
    padding-left: 10px;
}

.css-14el2xx-placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000 !important;
}

.map-button {
    position: absolute;
    bottom: 8%;
    left: 30%;
    width: 220px;
    height: 50px;
    padding: unset !important;
    background: #8A2432;
    border-radius: 8px;
}
.pickup-button {
    position: absolute;
    bottom: 3%;
    left: 30%;
    width: 220px;
    height: 50px;
    padding: unset !important;
    background: #8A2432;
    border-radius: 8px;
}

.modal-header .close {
    padding: 1rem;
    margin: -10rem -3rem -4rem  auto !important;
}

.modal-address-content {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #222720;
}

.modal-address-details {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #222720;
}

.modal-pickup-header {
    padding-right: 3rem;
    padding-left: 3rem;
    height: 43px;
    border-bottom: unset !important;
}

/* ABID CODE END */


.locationsec {
    padding: 25px 30px 30px;
    border: 1px #e5e5e5 solid;
}

.searchDesign {
    margin-top: 10px;
    position: relative;
    border: 1px solid #eaeaea;
    margin-bottom: 10px;
}

.image-banner-container {
    display: none;
}

.close-icon-times {
    position: absolute;
    right: 0;
    top: 25%;
    padding-right: 20px;
    font-size: 18px;
}

.custom-input {
    border: unset;
}

.locationTitle {
    font-size: 24px;
    text-transform: uppercase;
    color: #000;
    margin: 0 0 15px;
    font-family: Open Sans !important;
    letter-spacing: normal;
}


.loginCart {
    width: 100%;
    background-color: #f6f6f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px 12px 35px;
}

.loginCart p {
    color: #a6a7a8;
    margin: 0;
    font-family: Open Sans;
    font-size: 1.3rem;
    line-height: 1.9rem;
}

.orContinue {
    margin: 20px 0;
}

.loginCartButton {
    background-color: #8a2432;
    border: 0;
    color: #fff;
    font-size: 12px;
    padding: 6px 16px;
    box-shadow: none;
    text-transform: uppercase;
}

.deliverycart {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px 12px 35px;
}

.pickupcart {
    color: #fff;
    font-size: 12px;
    /* padding:6px 16px; */
    box-shadow: none;
    text-transform: uppercase;
}

.iwant-sec {
    float: left;
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    padding-bottom: 15px;
    border-bottom: unset;
}

.iwnt-toggleButton {
    margin: 0;
    display: flex;
    list-style: none;
    font-family: Open Sans !important;
    font-size: 15px;
    text-transform: uppercase;
}

.iwnt-toggleButton li {
    margin: 0 30px 0 0;
    padding: 5px 0 5px 0;
    display: flex;
    line-height: 1;
    align-items: center;
    cursor: pointer;
}

.order-method {
    font-family: Open Sans;
    width: 30%;
    padding: 10px;
    border: 2px solid #8a2432;
    color: #8a2432;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
}

.selected {
    font-family: Open Sans;
    background-color: #8a2432;
    color: #fff;
}

.cart-nowLater {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
    margin-left: -10%;
}

.later-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input-addressCart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
}

.name-deliveryCart {
    display: flex;
    flex-direction: row;
    align-content: center;
    padding: 20px;
    padding-top: 4%;
    background-color: transparent;
    padding-left: 0;
    justify-content: left
}

[dir="rtl"] .name-deliveryCart {
    justify-content: right;
}

.storeName-cartD {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    background-color: #fff;
    align-content: center;
    padding-top: 4%;
    margin-top: -25px;
}

/* Dropdown Button */

.dropbtn {
    background-color: #8a2432;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5px;
}

.storeShow-cart {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    color: white;
    background-color: #f3f1f9;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    /* width: 60%; */
}

.hideInputK {
    margin-top: -3%;
    display: flex;
    justify-content: space-around;
}

.notek {
    color: black;
    padding: 10px;
    width: 40%;
    padding-bottom: 50px;
}

.hideInputFieldK {
    width: 90%;
    height: 30%;
}

.hideInputFieldPickup {
    width: 40%;
    height: 40%;
    padding: 12px;
}


.find-store-banner {
    width: 100%;
    /* top: 30%; */
    padding: 15px;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.sl-hds {
    font-size: 24px;
    text-transform: uppercase;
    color: #000;
    margin: 0 0 15px;
    font-family: Open Sans !important;
    letter-spacing: normal;
}

@media only screen and (max-width: 768px) and (min-width: 300px) {
    h2 {
        font-size: 17px !important;
        letter-spacing: -1px;
    }

    #responsiveTabs {
        width: 33% !important
    }
}

.myButton {
    width: 100%;
}

.myButton:hover {
    background: linear-gradient(to bottom, #e4685d 5%, #fc8d83 100%);
    background-color: #e4685d;
}

.myButton:active {
    position: relative;
    top: 1px;
}

.lgor {
    font-family: Open Sans !important;
    text-align: center;
    font-size: 15px;
    margin: 0 0 20px;
    border-bottom: 1px dotted #c7c7c7;
    width: 80%;
    display: table;
    font-style: normal;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: #8a2432;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #8a2432;
}

#round {
    border-radius: 10px;
    border-color: gray;
    height: 10px;
}

#roundPick {
    border-radius: 10px;
    border-color: gray;
    height: 50px;
    width: 100%;
}

#roundSelect {
    border-radius: 10px;
    border-color: gray;
    height: 42px;
}

@media (max-width: 800px) {
    .noteK {
        width: 70%;
    }

    .hideInputFieldK {
        width: 70%;
        height: 15%;
    }

    #nowMobile {
        margin: 20px;
    }
}

.cart-summary h4 a:after {
    display: none !important;
}

.search-wrapper {

    display: flex;
    align-items: center;
    padding-right: 10px;
    border: 2px solid #4C4948;
    z-index: 9;
    background: #fff;
    width: 80%;
    margin-top: 5px;
    left: 5%;
    top: 5rem;
    position: absolute;
}

.autoComplete {
    outline: none;
    border-radius: unset;
    border: 2px solid;
}

.discountStyle {
    font-weight: bold;
    color: green;
}

.W-90 {
    width: 90%;
    border: unset;
}

.address-suggestion {
    position: absolute;
    background-color: #fff;
    /* position: absolute; */
    z-index: 100;
    width: 93%;
    top: 50px;
    width: 93%;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
}

.suggestion-item {
    padding: 12px;
    display: grid;
    cursor: pointer;
    border-bottom: 1px solid #eaeaea;
}

.suggestion-item:hover {
    background-color: #eaeaea;
}

.laterDateInput {
    padding: 5px;
    height: 4rem;
    border-color: gray;
    border-radius: 10px;
}

.font-color {
    color: #8a2432 !important;
}

.btn-bg-custom {
    background-color: #8a2432;
}

.cart-button {
    background-color: #8a2432 !important;
    color: #FFF !important;
    }
.cart-button:hover {
    background-color: #8a2432 !important;
    opacity: 1 !important;
}

.get_direction_btn {
    background-color: white !important;
    color:#8a2432;
    width:133px;
    padding:10px;
    border-radius: 8px;
    margin-right: 5px;
}

.modal-custom-style {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    height: 100vh;
}

.pinIcon {
    position: absolute;
    margin-left: -23px;
    margin-top: 90px;
    width: 40px;
    height: 40px;
    left: 50%;
    top: 15px;
    z-index: 1
}

.currentLocation {
    position: absolute;
    margin-left: 150px;
    margin-top: -4px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    left: 70%;
    top: 25px;
    z-index: 1
}

.autoComplete {
    width: 94%;
    height: 45px;
    padding: 20px;
    border: none;
    /* border-radius: 5px; */
    /* position: absolute; */
    top: 110px;
    z-index: 1
}

.custom-focus-control:focus {
    /* border-color: #28a745; */
    box-shadow: 0 0 0 0.2rem rgb(149, 10, 28, 0.75);
}

/* //To make map resposive */
.flat-list-container {
    height: 56vh !important;
    overflow-y: auto;
}

.active-item1 {
    background: #8a2432 !important;
    color: #fff;
}
.active-item1 .myprofile {
    font-family: Open Sans;
    color: white !important;
}

.item {
    padding: 10px;
    background: #fafafa;
    border-bottom: 3px solid white;
    cursor: pointer;
}

@media only screen and (min-width: 992px) {
    .container-position {
        position: relative;
        top: 50%;
        left: 20%;
    }

    [dir="rtl"] .container-position {
        left: 0%;
        right: 15%;
    }
}

@media only screen and (min-width: 1201px) {
    [dir="rtl"] .currentLocation {
        margin-right: 150px;
        right: 70%
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .currentLocation {
        margin-left: 110px;
    }

    [dir="rtl"] .currentLocation {
        margin-right: 150px;
        right: 70%
    }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
    .pinIcon {
        margin-left: -23px !important;
        margin-top: 90px !important;
        left: 50% !important;
        top: 20px !important;
    }

    .autoComplete {
        /* margin-left: 10px !important; */
        top: 110px !important;
    }

    .currentLocation {
        margin-left: 80px;
        margin-top: -5px;
        left: 80%;
    }

    [dir="rtl"] .currentLocation {
        margin-right: 80px;
        right: 75%
    }

    [dir="rtl"] .autoComplete {
        margin-right: 20px !important;
    }
}

@media only screen and (min-width: 560px) and (max-width: 768px) {
    .pinIcon {
        margin-left: -23px !important;
        margin-top: 75px !important;
        left: 50% !important;
        top: 30px !important;
    }

    .autoComplete {
        /* margin-left: 10px !important; */
        top: 92px !important;
    }

    .currentLocation {
        margin-left: 50px;
        margin-top: -5px;
        left: 80%;
    }

    [dir="rtl"] .currentLocation {
        margin-right: 80px;
        right: 75%
    }
}

@media only screen and (min-width: 290px) and (max-width: 559px) {
    .pinIcon {
        margin-left: -23px !important;
        margin-top: 70px !important;
        left: 50% !important;
        top: 35px !important;
    }

    .image-banner-container {
        display: block;
    }



    .autoComplete {
        width: 94%;
        margin-right: 10px !important;
        top: 87px !important;
    }

    .currentLocation {
        margin-left: 50px;
        margin-top: -5px;
    }

    [dir="rtl"] .currentLocation {
        margin-right: 40px;
        right: 70%
    }

    [dir="rtl"] .autoComplete {
        margin-right: 20px !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
    .currentLocation {
        margin-left: 45px;
        margin-top: -5px;
    }
}

.location-form {
    padding-top: 70px;
    padding-bottom: 70px;
}

.custom-btn {
    height: 60px;
    width: 30%;
}

.btn-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-group {
    display: flex;
    align-items: center;
    justify-content: center;

}

.text-between {
    padding: 10px;
    font-weight: 600;

    font-family: Open Sans;
}

.address-suggestion {
    background-color: #fff;
    /* position: absolute; */
    top: 100%;
    z-index: 100;
    width: 100%;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
}

.suggestion-item {
    padding: 12px 0 12px 24px;
}

.suggestion-item:hover {
    background-color: #eaeaea;
}

.accordion-top {
    width: 100%;
    display: flex;
    padding: 10px;
    background: #f3f1f9;
}

.border-radius-remove {
    border-radius: unset !important;
    border-top: 1px solid;
}

@media screen and (max-width: 500px) {
    .button-group {
        flex-direction: column;
    }

    .custom-btn {
        width: 100%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 988px) {
    .landing-outer {
        width: 100%;
        display: unset !important;
        margin-top: 5.6rem !important;
    }
    .add-another-address-button{
        margin-left: 26%;
    }

    .video-banner-container {
        width: auto;

        height: auto !important;

        /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(.jpg); */
    }

}

@media screen and (max-width: 990px) {
    .btn-row {
        margin-top: 10px;
    }

    .custom-btn {
        width: 100%;
    }
}




.video-banner-container {
    width: auto;

    height: 40rem;

    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(.jpg); */
}

.banner-content {
    font-family: Open Sans;
    font-Weight: 700;
    font-size: 40px;
    line-height: 59.6px;
}

.btn-outer-left {
    background: #FF8300 !important;
    border-radius: 12px;
    width: 100%;
    font-family: Open Sans;
    height: 52px;
    border: none;
    color: #FFF !important;
    cursor: pointer;

}

.btn-outer-right {
    background: #8E634A !important;
    border-radius: 12px;
    width: 100%;
    font-family: Open Sans;
    height: 52px;
    border: none;
    cursor: pointer;
    color: #FFF !important;
}

.landing-outer {
    width: 100%;
    display: flex;
    /* margin-top: 5rem !important; */
}

.or-text {
    font-size: 20px;
    font-weight: 600;
    border: none;
    background-color: transparent;
    font-family: Open Sans;
}
@media only screen and (min-width: 320px) and (max-width: 992px){
    .banner-content {
        font-family: Open Sans;
        font-Weight: 700;
        font-size: 40px;
        line-height: unset !important;
        text-align: center;
    }
    .delievery-title {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 31px;
        position: absolute;
        color: #000000;
        top: 1rem;
    }
    .pickup-button {
        position: absolute;
        bottom: 3%;
        left: unset;
        width: 220px;
        height: 50px;
        padding: unset !important;
        background: #8A2432;
        border-radius: 8px;
    }
    .map-button {
        position: absolute;
        bottom: 8%;
        left: 20%;
        width: 220px;
        height: 50px;
        padding: unset !important;
        background: #8A2432;
        border-radius: 8px;
    }
    .landing-banner-image{
        width: 100%;
    }
}

@media (max-width:559px) {
    body {
        font-size: 16px;
      }
      input[type="text"] {
        font-size: inherit;
      }
}