table {
    width: 100%;
    text-align: center;
    border: 1px solid rgb(41, 41, 41);
    border-collapse: collapse;
}

thead {
    background-color: #830029;
    color: white
}

td {
    color: #7b858a;
}

th {
    border: 1px solid rgb(255,255,255,0.5)
}

tr, td {
    border: 1px solid rgb(41, 41, 41, 0.3)
}

td, th {
    padding: 15px;
}

.table-wrapper {
    overflow-x: scroll;
    white-space: wrap;
}

@media (max-width: 990px) {
    td {
        text-wrap: nowrap;
    }
}

#cookies p strong {
    font-size: 16px;
}